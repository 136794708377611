'use client'

import { Spinner } from '@material-tailwind/react'
import clsx from 'clsx'
import { signIn } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import { useSearchParams } from 'next/navigation'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'

import { Link } from '@/i18n'

const formSchema = z.object({
  email: z
    .string()
    .email(
      `Your username is a valid email address.  If you are having issues with your username, please reach out to CGN's support team.`
    )
    .min(1, 'A username is required'),
  password: z.string().min(1, 'A password is required'),
})

type LoginFormSchemaType = z.infer<typeof formSchema>

type ILoginFormInputs = LoginFormSchemaType
/**
 * Intended to be imported into an SSR page in most cases (hopefully)
 */

const EmailLoginForm = () => {
  const t = useTranslations('Login')
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm<ILoginFormInputs>()
  const searchParams = useSearchParams()
  const callbackUrl = searchParams.get('callbackUrl') || '/home'

  const errorFromCode = (): string | null => {
    switch (searchParams.get('error')) {
      case 'CredentialsSignin':
        return t('unableToLogYouIn')
        break
      default:
        return null
        break
    }
  }

  const [loginError] = useState<string | null>(errorFromCode())

  const onSubmit: SubmitHandler<ILoginFormInputs> = async (data) => {
    try {
      await signIn('credentials', {
        email: data.email,
        password: data.password,
        callbackUrl: callbackUrl,
      })
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div>
      {loginError && <p className="my-2 block border-2 bg-red-400 p-4 text-left text-white">{loginError}</p>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="m-2 w-full">
          <label htmlFor="email" className="block text-lg">
            {t('email')}
          </label>
          {errors.email && <span className="text-red-400">{t('thisFieldIsRequired')}</span>}
          <input
            {...register('email', { required: true })}
            placeholder="example@example.com"
            className="w-full"
            tabIndex={1}
          />
        </div>
        <div className="m-2 w-full">
          <label htmlFor="password" className="text-lg">
            {t('password')}
          </label>
          <Link
            href="/forgot-password"
            className="float-right align-text-bottom text-sm text-gray-500 underline"
            tabIndex={4}
          >
            {t('forgotPassword')}
          </Link>
          {errors.password && <span className="text-red-400">{t('thisFieldIsRequired')}</span>}
          <input {...register('password', { required: true })} type="password" className="w-full" tabIndex={2} />
        </div>
        <button
          type="submit"
          className={clsx(
            'bg-brand-purple-800 inline-block h-[3rem] w-full rounded-lg px-6 py-2',
            'text-center text-2xl text-white',
            'my-4',
            'disabled:opacity-50'
          )}
          onClick={handleSubmit(onSubmit)}
          tabIndex={3}
          disabled={!isValid || isSubmitting || !isDirty}
        >
          {isSubmitting ? (
            <Spinner className="h-4 w-4 text-white mx-auto" />
          ) : (
            t('loginWith', { provider: t('email').toLocaleLowerCase() })
          )}
        </button>
      </form>
    </div>
  )
}

export default EmailLoginForm

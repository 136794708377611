import { signIn } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import Image from 'next/image'

import CleverLoginImage from './buttons/LogInWithClever.png'

interface ICleverLoginButtonProps {
  callbackUrl: string
}
const CleverLoginButton = (props: ICleverLoginButtonProps) => {
  const { callbackUrl } = props
  const t = useTranslations('Login')
  return (
    <div
      className="relative flex h-[2.6rem] w-full items-center justify-center p-0"
      onClick={() => signIn('clever', { callbackUrl: callbackUrl })}
    >
      <Image
        src={CleverLoginImage}
        fill={true}
        style={{ objectFit: 'scale-down' }}
        alt={t('loginWith', { provider: 'Clever' })}
        className="cursor-pointer"
      />
    </div>
  )
}

export default CleverLoginButton
